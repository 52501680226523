import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import styles from './shows.module.css';

const UpcomingShow = ( { datetime, group, venue, location } ) => {
	const renderDate = useCallback( () => new Date( datetime )
		.toLocaleDateString( 'en', { weekday: 'short', month: 'short', day: '2-digit' } ), [ datetime ] );
	const renderTime = useCallback( () => new Date( datetime )
		.toLocaleTimeString( 'en-US', { hour: 'numeric', minute: '2-digit' } )
		.replace( ':00', '' )
		.replace( ' ', '' ), [ datetime ] );

	return (
		<div className={ styles.upcomingShow }>
			<div className={ styles.dateTime }>
				<span className={ styles.date }>{ renderDate() }</span>
				&nbsp;&middot;&nbsp;
				<span className={ `${ styles.time } no-break` }>{ renderTime() }</span>
			</div>
			<div className={ styles.details }>
				<span className={ styles.title }><span className="no-break">{ group }</span> @ <span className="no-break">{ venue }</span></span>
				<span className={ styles.location }>{ location }</span>
			</div>
		</div>
	);
};

UpcomingShow.propTypes = {
	datetime: PropTypes.string.isRequired,
	group: PropTypes.string.isRequired,
	venue: PropTypes.string.isRequired,
	location: PropTypes.string.isRequired
};

export default UpcomingShow;

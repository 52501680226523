import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Show from './Show';
import UpcomingShow from './UpcomingShow';

import styles from './shows.module.css';

const Shows = ( { className, emptyMessage, shows, upcoming } ) => {
	const mapShows = useCallback( () => {
		return shows.map( show => <Show key={ show.datetime } { ...show } /> );
	}, [ shows ] );

	const mapUpcomingShows = useCallback( () => {
		return shows.map( show => <UpcomingShow key={ show.datetime } { ...show } /> );
	}, [ shows ] );

	return (
		<div className={ `${ styles.shows } ${ className }` }>
			{ shows.length
				? upcoming
					? mapUpcomingShows()
					: mapShows()
				: <p>{ emptyMessage }</p>
			}
		</div>
	);
};

Shows.defaultProps = {
	className: '',
	emptyMessage: 'They are currently no shows. Please check back for updates.',
	shows: []
};

Shows.propTypes = {
	className: PropTypes.string,
	emptyMessage: PropTypes.string,
	shows: PropTypes.array,
	upcoming: PropTypes.bool
};

export default Shows;

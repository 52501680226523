import { useStaticQuery, graphql } from 'gatsby';

const useShows = ( {
	limit,
	past,
	upcoming,
	year,
	sort
} = {} ) => {
	const { allFile: { nodes } } = useStaticQuery( graphql`
		query Shows {
			allFile(filter: {relativeDirectory: {eq: "shows"}}) {
				nodes {
					childMarkdownRemark {
						frontmatter {
							datetime
							group
							venue
							location
						}
					}
				}
			}
		}
	` );

	let shows = nodes.map( ( { childMarkdownRemark: { frontmatter } } ) => {
		return { ...frontmatter };
	} );

	if ( sort === 'desc' ) {
		shows.sort( ( a, b ) => new Date( b.datetime ).getTime() - new Date( a.datetime ).getTime() );
	} else {
		shows.sort( ( a, b ) => new Date( a.datetime ).getTime() - new Date( b.datetime ).getTime() );
	}

	if ( upcoming ) { // return upcoming shows
		shows = shows.filter( show => {
			const now = new Date().getTime();
			const showDate = new Date( show.datetime ).getTime();

			return showDate >= now;
		} );
	}

	if ( past ) { // return one year's worth of past shows
		shows = shows.filter( show => {
			const now = new Date().getTime();
			const showDate = new Date( show.datetime ).getTime();
			const aYearAgo = showDate - 365 * 24 * 60 * 60 * 1000;

			return showDate < now && showDate >= aYearAgo;
		} );
	}

	if ( year ) { // return all shows for a specific year
		shows = shows.filter( show => new Date( show.datetime ).getFullYear() === year );
	}

	let end = limit || shows.length;

	return shows.slice( 0, end );
};

export default useShows;

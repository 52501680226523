import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import AddToCalendar from 'react-add-to-calendar';

import styles from './shows.module.css';

const Show = ( { datetime, group, venue, location } ) => {
	const renderDate = useCallback( () => new Date( datetime )
		.toLocaleDateString( 'en', { month: 'short', day: '2-digit' } ), [ datetime ] );
	const renderTime = useCallback( () => new Date( datetime )
		.toLocaleTimeString( 'en-US', { hour: 'numeric', minute: 'numeric' } )
		.replace( ':00', '' )
		.replace( ' ', '' ), [ datetime ] );

	const event = {
		title: `${ group } @ ${ venue }`,
		description: `${ group } performs at ${ venue } in ${ location } on ${ renderDate() } at ${ renderTime() }`,
		location,
		startTime: datetime
	};

	const isUpcoming = useCallback( () => new Date( datetime ).getTime() >= new Date().getTime(), [ datetime ] );

	return (
		<div className={ styles.show }>
			<div className={ styles.dateTime }>
				<span className={ `${ styles.date } no-break` }>{ renderDate() }</span>
				<span className={ styles.separator }>&nbsp;&middot;&nbsp;</span>
				<span className={ `${ styles.time } no-break` }>{ renderTime() }</span>
			</div>
			<div className={ styles.details }>
				<span className={ styles.title }><span className="pre-wrap">{ group }</span><span className="pre-wrap"> @&nbsp;{ venue }</span></span>
				<span className={ styles.location }>{ location }</span>
			</div>
			<div className={ styles.actions }>
				{ isUpcoming()
					? <AddToCalendar
						buttonLabel=""
						buttonTemplate={ { 'calendar-plus': 'left' } }
						buttonWrapperClass={ styles.atcButton }
						dropdownClass={ styles.atcDropdown }
						event={ event }
						rootClass={ styles.atcShort }
					/>
					: null
				}
			</div>
		</div>
	);
};

Show.propTypes = {
	datetime: PropTypes.string.isRequired,
	group: PropTypes.string.isRequired,
	venue: PropTypes.string.isRequired,
	location: PropTypes.string.isRequired
};

export default Show;
